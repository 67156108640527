import React, { useRef } from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { LazyMotion, m } from 'framer-motion'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const loadFeatures = () => import('../features.js').then(res => res.default)

const Animation = styled(props => <m.div {...props} />)`
  ${tw`fixed bottom-0 right-0 z-50`}
  bottom: -20rem;
`

const CookieConsentPopup = () => {
  const location = useLocation()

  const cookieConsentRef = useRef()
  return (
    <LazyMotion features={loadFeatures}>
      <Animation animate={{ bottom: 0 }} transition={{ delay: 1.5, duration: 1, ease: 'easeOut' }}>
        <CookieConsent
          ref={cookieConsentRef}
          disableStyles
          enableDeclineButton
          buttonId="accept-cookie"
          containerClasses="w-full md:mr-5 md:mb-5 md:max-w-lg pl-6 pr-10 py-5 bg-white z-50 shadow-md md:rounded-md"
          contentClasses="text-xs md:text-sm"
          declineButtonClasses="font-medium text-sm"
          buttonClasses="bg-primary px-5 md:px-8 py-2 text-white font-medium mt-3 ml-3"
          declineButtonText="No, thanks"
          buttonText="I accept"
          cookieName="gatsby-gdpr-google-tagmanager"
          onAccept={() => {
            initializeAndTrack(location)
          }}
        >
          <button
            type="button"
            aria-label="I accept"
            className="absolute right-0 md:right-5 top-0 p-4"
            onClick={() => cookieConsentRef.current.accept()}
          >
            <FontAwesomeSvgIcon icon={faTimes} className="w-4 cursor-pointer text-black" />
          </button>
          We use cookies to track visitor data. If you continue to use our services, we will assume that you agree to
          the use of such cookies.{' '}
          <Link to="/privacy-policy" className="underline">
            Read more here
          </Link>
        </CookieConsent>
      </Animation>
    </LazyMotion>
  )
}

export default CookieConsentPopup
