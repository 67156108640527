export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const colors = {
  primary: '#FF5B1B',
}
