import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { m, LazyMotion } from 'framer-motion'

const loadFeatures = () => import('../features.js').then(res => res.default)

const Image = styled(props => <m.div {...props} />)`
  ${tw`py-10 md:px-8 max-w-6xl mx-auto text-center`}
`

const Caption = styled.figcaption`
  ${tw`text-xs mt-4`}
`

const ImageFullWidth = ({ image }) => {
  const imageGatsbyData = getImage(image?.localFile.childImageSharp.gatsbyImageData)
  return (
    <LazyMotion features={loadFeatures}>
      <Image
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <GatsbyImage image={imageGatsbyData} alt={image?.title ? image.title : ''} />
        {image?.altText && <Caption>{image.altText}</Caption>}
      </Image>
    </LazyMotion>
  )
}

ImageFullWidth.propTypes = {
  image: PropTypes.object.isRequired,
}

export const ImageFullWidthData = graphql`
  fragment ImageFullWidth on WpPage_Pagebuilder_Layouts_ImageFullWidth {
    fieldGroupName
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 1090, placeholder: BLURRED)
        }
      }
      altText
      title
    }
  }

  fragment ImageFullWidthWork on WpWork_Pagebuilder_Layouts_ImageFullWidth {
    fieldGroupName
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 1090, placeholder: BLURRED)
        }
      }
      altText
      title
    }
  }
`

export default ImageFullWidth
