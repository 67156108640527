import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { LazyMotion, m } from 'framer-motion'

const loadFeatures = () => import('../features.js').then(res => res.default)

const Wrapper = styled(props => <Link {...props} />)`
  ${tw`inline-block text-lg pl-6 pr-4 py-3 mb-10
  bg-black hover:bg-primary text-white font-bold transition-colors duration-300 ease-in-out
  transform
  focus:outline-none rounded`}
`

const Animation = styled(props => <m.button {...props} />)``

const Icon = styled(props => <FontAwesomeSvgIcon {...props} />)`
  ${tw`ml-2`}
`

const Button = ({ url, title }) => (
  <LazyMotion features={loadFeatures}>
    <Animation whileHover={{ scale: 1.075 }} whileTap={{ scale: 0.9 }} type="button" aria-label={title}>
      <Wrapper to={url} aria-label={title}>
        {title} <FontAwesomeSvgIcon icon={faLongArrowRight} className="w-5 inline-block ml-2" />
      </Wrapper>
    </Animation>
  </LazyMotion>
)

Button.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
}

export default Button
