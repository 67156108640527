import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'
import Card from '../components/Work/Card'

const WorkListingHome = () => {
  const data = useStaticQuery(graphql`
    query WorkHome {
      allWpWork(limit: 4, sort: { date: DESC }) {
        nodes {
          ...WorkContent
        }
      }
    }
  `)

  const { nodes } = data.allWpWork

  const WorkListing = styled.div`
    ${tw`md:flex md:flex-wrap`}
  `

  const CardWrapper = styled.div`
    ${tw`md:w-1/2 mb-8 md:mb-4`}

    &:first-of-type {
      ${tw`mt-16`}
    }
    :nth-of-type(even) {
      figure {
        ${tw`ml-12 md:ml-2`}
      }
    }
    :nth-of-type(odd) {
      figure {
        ${tw`mr-12 md:mr-2`}
      }
    }
    :nth-of-type(4) {
      figure {
        @media (min-width: ${breakpoints.md}px) {
          margin-top: -4rem;
        }
      }
    }
  `

  return (
    <WorkListing>
      {nodes.map(work => (
        <CardWrapper key={work.key}>
          <Card
            url={work.uri}
            title={work.title}
            color={work.projectDetails.tileColor}
            image={work.featuredImage.node}
          />
        </CardWrapper>
      ))}
    </WorkListing>
  )
}

export default WorkListingHome

export const WorkListingHomeData = graphql`
  fragment WorkListingHome on WpPage_Pagebuilder_Layouts_WorkListingHome {
    fieldGroupName
  }
`
