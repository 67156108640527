/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import '../styles/global.css'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Header from './Header/Header'
import Footer from './Footer'
import CookieConsentPopup from './CookieConsent'
import Transition from './Transition'
import Menu from './Header/Menu'
import { breakpoints } from '../utils/styles'

const Wrap = styled.div`
  padding-top: 4.5rem;

  @media (min-width: ${breakpoints.md}px) {
    padding-top: 7rem;
  }
`

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Menu />
      <Wrap>
        <Transition location={location}>
          <>
            {children}
            <Footer />
          </>
        </Transition>
        <CookieConsentPopup />
      </Wrap>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
