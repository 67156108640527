import React, { useContext } from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Squash as Hamburger } from 'hamburger-react'
import { breakpoints } from '../../utils/styles'
import { GlobalDispatchContext, GlobalStateContext } from '../../contexts/GlobalContextProvider'

const Button = styled.div`
  ${tw`fixed z-50 cursor-pointer outline-none`}
  border-radius: 50%;
  top: 0.25rem;
  right: 0.5rem;
  @media (min-width: ${breakpoints.md}px) {
    top: 1rem;
    right: 1.5rem;
  }

  .hamburger-react div div {
    height: 2px !important;
  }
`

const MenuToggle = () => {
  const dispatch = useContext(GlobalDispatchContext)

  const handleToggle = () => {
    dispatch({ type: 'MENU_IS_OPEN' })
  }

  const state = useContext(GlobalStateContext)

  const { menuIsOpen } = state

  return (
    <Button>
      <Hamburger toggled={menuIsOpen} toggle={() => dispatch({ type: 'MENU_IS_OPEN' })} label="Toggle menu" />
    </Button>
  )
}

export default MenuToggle
