import React, { useContext, useEffect } from 'react'
import { m, AnimatePresence, LazyMotion } from 'framer-motion'
import PropTypes from 'prop-types'
import { GlobalDispatchContext, GlobalStateContext } from '../contexts/GlobalContextProvider'

const loadFeatures = () => import('../features.js').then(res => res.default)

const Transition = ({ children, location }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const { isInitialLoad } = state

  const duration = 0.35

  const variants = {
    initial: {
      opacity: 0,
      y: isInitialLoad ? 0 : 400,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration,
        delay: duration,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      y: -400,
      transition: { duration: 0.35, ease: 'easeInOut' },
    },
  }

  useEffect(() => {
    dispatch({ type: 'IS_INITIAL_LOAD' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LazyMotion features={loadFeatures}>
      <AnimatePresence>
        <m.div key={location?.pathname} variants={variants} initial="initial" animate="enter" exit="exit">
          {children}
        </m.div>
      </AnimatePresence>
    </LazyMotion>
  )
}

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Transition
