import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import FeaturedImage from './FeaturedImage'

const Card = ({ url, color, title, image }) => {
  const Wrapper = styled(props => <Link {...props} />)`
    ${tw`block hover:opacity-75 transition-opacity duration-300 ease-in-out`}

    .gatsby-image-wrapper {
      img {
        width: 100% !important;
      }
    }
  `

  return (
    <Wrapper to={url} aria-label={title}>
      <FeaturedImage color={color} image={image} title={title} />
    </Wrapper>
  )
}

Card.propTypes = {
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default Card
