import React from 'react'
import { m, LazyMotion } from 'framer-motion'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import MenuItem from './MenuItem'
import flatListToHierarchical from '../../utils/flatListToHierarchical'

const loadFeatures = () => import('../../features.js').then(res => res.default)

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query HEADER_MENU_ITEMS {
      menu: allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
        nodes {
          ...MenuItem
        }
      }
    }
  `)

  const { menu } = data

  const menuItems = flatListToHierarchical(menu?.nodes)

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  const Wrapper = styled.div`
    ${tw`py-16 md:py-20`}
  `

  return (
    <LazyMotion features={loadFeatures}>
      <Wrapper as={m.ul} variants={variants}>
        {menuItems?.map(i => (
          <MenuItem i={i} key={i.key} />
        ))}
      </Wrapper>
    </LazyMotion>
  )
}

export default Navigation
