import React, { useContext, useEffect } from 'react'
import { m, LazyMotion, AnimatePresence } from 'framer-motion'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { lock, unlock } from 'tua-body-scroll-lock'
import useKeypress from 'react-use-keypress'
import { GlobalDispatchContext, GlobalStateContext } from '../../contexts/GlobalContextProvider'
import Navigation from './Navigation'

const loadFeatures = () => import('../../features.js').then(res => res.default)

const Wrap = styled(props => <div {...props} />)`
  ${tw`z-40 fixed top-0 right-0 bottom-0 `}
  height: 100vh;
`

const SideBar = styled.div`
  ${tw` bg-primary`}
  height: 100vh;
  min-width: 400px;
  max-width: 500px;
  width: 40vw;
`

const Overlay = styled.div`
  ${tw`fixed top-0 right-0 left-0 bottom-0 z-30 cursor-pointer bg-black`}
  width: 100%;
  height: 100vh;
`

const Menu = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const { menuIsOpen } = state

  const toggleMenu = () => {
    dispatch({ type: 'MENU_IS_OPEN' })
  }

  useKeypress('Escape', () => {
    if (menuIsOpen) {
      toggleMenu()
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const targetElement = document.querySelector('#side-bar')
      if (menuIsOpen) {
        lock(targetElement)
      } else {
        unlock(targetElement)
      }
    }
  }, [menuIsOpen])

  return (
    <>
      <Wrap>
        <LazyMotion features={loadFeatures}>
          <AnimatePresence>
            {menuIsOpen && (
              <SideBar
                id="side-bar"
                as={m.div}
                animate={{ x: 0 }}
                initial={{ x: 500 }}
                exit={{ x: 500 }}
                transition={{ ease: 'easeInOut' }}
              >
                <Navigation toggle={() => toggleMenu()} />
              </SideBar>
            )}
          </AnimatePresence>
        </LazyMotion>
      </Wrap>
      <LazyMotion features={loadFeatures}>
        <AnimatePresence>
          {menuIsOpen && (
            <Overlay
              as={m.div}
              onClick={() => toggleMenu()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.75 }}
              exit={{ opacity: 0 }}
            />
          )}
        </AnimatePresence>
      </LazyMotion>
    </>
  )
}

export default Menu
