import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { m, LazyMotion } from 'framer-motion'

const loadFeatures = () => import('../features.js').then(res => res.default)

const Wrap = styled(props => <m.div {...props} />)`
  ${tw`py-8 md:py-10 px-8 md:px-20 lg:px-8 max-w-4xl mx-auto md:text-lg text-center`}

  p {
    ${tw`mb-10`}
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    ${tw`inline-block relative`}
  }
`

const TextBlock = ({ text }) => (
  <LazyMotion features={loadFeatures}>
    <Wrap
      className="prose md:prose-lg"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    />
  </LazyMotion>
)

TextBlock.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TextBlock

export const TextBlockData = graphql`
  fragment TextBlock on WpPage_Pagebuilder_Layouts_TextBlock {
    fieldGroupName
    text
  }

  fragment TextBlockWork on WpWork_Pagebuilder_Layouts_TextBlock {
    fieldGroupName
    text
  }
`
