import React, { useContext } from 'react'
import { m, LazyMotion } from 'framer-motion'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { GlobalDispatchContext } from '../../contexts/GlobalContextProvider'

const loadFeatures = () => import('../../features.js').then(res => res.default)

const MenuItem = ({ i }) => {
  const { title, url } = i
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  const Wrapper = styled.li``

  const Item = styled(props => <Link {...props} />)`
    ${tw`block px-10 md:px-16 py-4 md:py-3 font-medium text-white text-2xl md:text-4xl hover:text-black`}

    &.active {
      ${tw`text-white`}
    }
  `

  const dispatch = useContext(GlobalDispatchContext)

  const handleToggle = () => {
    dispatch({ type: 'MENU_IS_OPEN' })
  }

  return (
    <LazyMotion features={loadFeatures}>
      <Wrapper as={m.li} variants={variants}>
        <div className="icon-placeholder" />
        <div className="text-placeholder" />
        <Item to={url} onClick={handleToggle}>
          {title}
        </Item>
      </Wrapper>
    </LazyMotion>
  )
}

MenuItem.propTypes = {
  i: PropTypes.object.isRequired,
}

export default MenuItem
