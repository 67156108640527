import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FeaturedImage = ({ color, image, title }) => {
  const Image = styled.figure`
    background-color: ${color};
  `

  const imageGatsbyData = getImage(image?.localFile.childImageSharp.gatsbyImageData)

  return (
    <Image>
      <GatsbyImage image={imageGatsbyData} alt={title} />
    </Image>
  )
}

FeaturedImage.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default FeaturedImage
