import { Link } from 'gatsby'
import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { breakpoints, colors } from '../../utils/styles'
import LogoGraphic from '../../assets/sz-logo.svg'
import MenuToggle from './MenuToggle'

const Header = () => {
  const Wrap = styled.header`
    ${tw`fixed flex w-full z-50`}
  `

  const Logo = styled.div`
    ${tw`pl-4 lg:pl-8 py-5 lg:py-7 md:mt-0`}

    svg {
      fill: ${colors.primary};
      width: 6rem;
      @media (min-width: ${breakpoints.md}px) {
        width: 10rem;
      }
    }
  `

  return (
    <Wrap>
      <Logo>
        <Link to="/" title="Sandström / Zethelius">
          <LogoGraphic />
        </Link>
      </Logo>
      <MenuToggle />
    </Wrap>
  )
}

export default Header
