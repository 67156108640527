/* eslint-disable prettier/prettier */
import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { breakpoints } from '../utils/styles'
import Button from '../components/Button'
import WordList from '../components/WordList'

const Wrap = styled.div`
  ${tw`max-w-5xl md:ml-20`}
`

const Content = styled.div`
  ${tw`text-left px-6 md:px-10 pt-20 md:py-16`}
`

const PageTitle = styled.h2`
  ${tw`mb-5 md:mb-10
  text-4xl font-bold leading-none`}

  font-size: 12vw;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 7.2vw;
  }

  i {
    ${tw`absolute text-primary`}
    font-style: normal;
  }
`

const Leading = styled.div`
  ${tw`sm:text-lg md:px-0 mb-8`}

  max-width: 600px;

  h1 {
    ${tw`inline`}
  }

  div {
    ${tw`mb-5 md:mb-8`}
  }
`

const Hero = ({ leading, button }) => (
  <Wrap>
    <Content>
      <PageTitle>
        <span className="block md:inline-block">
          We create&nbsp; </span><WordList />
        <br />
        websites
      </PageTitle>
      <Leading
        dangerouslySetInnerHTML={{
          __html: leading,
        }}
      />
      <Button url={button.url} title={button.title} />
    </Content>
  </Wrap>
)

Hero.propTypes = {
  button: PropTypes.object,
  leading: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Hero

export const HeroData = graphql`
  fragment Hero on WpPage_Pagebuilder_Layouts_Hero {
    fieldGroupName
    title
    leading
    button {
      url
      title
    }
  }
`
