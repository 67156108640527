import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { shuffle } from 'lodash'
import PropTypes from 'prop-types'
import { breakpoints } from '../utils/styles'

const Clients = ({ client }) => {
  const Wrap = styled.div`
    ${tw`max-w-6xl mx-auto px-8 pt-32 pb-20
    text-center font-bold`}
  `

  const Header = styled.h2`
    ${tw`mb-20
    text-2xl md:text-4xl`}
  `

  const NameList = styled.ul``

  const Name = styled.li`
    ${tw`mb-4 leading-none 
    text-7xl
    opacity-50 hover:opacity-100
    transition-opacity duration-500 ease-in-out`}
    @media (max-width: ${breakpoints.md}px) {
      font-size: 8vw;
    }
  `

  const [clientList, setClientList] = useState()

  useEffect(() => {
    setClientList(shuffle(client))
  }, [client])

  return (
    <Wrap>
      <Header>Notable clients, past and present</Header>
      <NameList>
        {clientList?.map(item => (
          <Name key={item.key}>{item.name}</Name>
        ))}
      </NameList>
    </Wrap>
  )
}

Clients.propTypes = {
  client: PropTypes.array.isRequired,
}

export default Clients

export const ClientsData = graphql`
  fragment Clients on WpPage_Pagebuilder_Layouts_Clients {
    fieldGroupName
    client {
      name
      key: id
    }
  }
`
