import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import Obfuscate from 'react-obfuscate'
import { Emoji } from '.'

const Footer = () => {
  const Wrap = styled.div`
    ${tw`text-center`}
  `

  const Content = styled.div`
    ${tw`mx-auto p-16 bg-white text-xl text-center font-bold`}

    a {
      ${tw`text-primary`}
    }
  `

  const Email = styled.div`
    ${tw``}

    a {
      ${tw`text-primary inline-block ml-1 md:ml-2`}
    }
  `

  return (
    <Wrap>
      <Content>
        Want to know more? Let’s connect
        <Email>
          <Emoji symbol="📮" label="mailbox" />
          <Obfuscate
            email="hello@s-z.se"
            headers={{
              cc: 'mikael@s-z.se',
              subject: 'Hey!',
            }}
          />
        </Email>
      </Content>
      {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
    </Wrap>
  )
}

export default Footer
