import React from 'react'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  menuIsOpen: false,
  isInitialLoad: true,
}

function reducer(state, action) {
  switch (action.type) {
    case 'MENU_IS_OPEN':
      return {
        ...state,
        menuIsOpen: !state.menuIsOpen,
      }

    case 'CLOSE_MENU':
      return {
        ...state,
        menuIsOpen: false,
      }
    case 'IS_INITIAL_LOAD':
      return {
        ...state,
        isInitialLoad: false,
      }
    default:
      throw new Error('Bad action type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider

// https://codeburst.io/global-state-with-react-hooks-and-context-api-87019cc4f2cf
