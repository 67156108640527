import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'

const Details = ({ client, tileColor, title }) => {
  const Wrap = styled.div`
    ${tw`flex p-8 md:p-10 lg:p-16 py-10 md:py-16 lg:py-24`}
    background-color: ${tileColor}
  `

  const TitleWrap = styled.div`
    ${tw`flex-1 tracking-widest leading-none text-white`}
  `

  const Title = styled.h1`
    ${tw`mb-2 md:mb-3 lg:mb-4 pr-10 
  text-4xl md:text-5xl lg:text-6xl font-bold`}
  `

  const Client = styled.h2`
    ${tw`uppercase text-xl md:text-3xl font-bold opacity-50`}
  `

  return (
    <Wrap>
      <TitleWrap>
        <Title>{title}</Title>
        <Client>{client}</Client>
      </TitleWrap>
    </Wrap>
  )
}

Details.propTypes = {
  client: PropTypes.string,
  tileColor: PropTypes.string,
  title: PropTypes.string,
}

export default Details
