import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import FeaturedImage from '../components/Work/FeaturedImage'

const WorkListing = () => {
  const data = useStaticQuery(graphql`
    query Work {
      work: allWpWork(sort: { date: DESC }) {
        nodes {
          ...WorkContent
        }
      }
    }
  `)

  const { nodes } = data.work

  const Wrap = styled.div`
    ${tw`md:flex md:flex-wrap px-4 md:px-6 mb-10`}
    a {
      ${tw`block py-4 md:py-2 md:w-1/2 md:px-2
      hover:opacity-75
      transition-opacity duration-300 ease-in-out`}
    }
  `

  const Details = styled.div`
    ${tw`p-8 py-10
    text-white tracking-widest leading-none`}
  `

  const TitleWrap = styled.div`
    ${tw`flex-1 tracking-widest font-bold leading-none`}
  `

  const Title = styled.h3`
    ${tw`mb-2 pr-10 
  text-2xl md:text-3xl`}
  `

  const Client = styled.h4`
    ${tw`uppercase text-lg md:text-xl opacity-50`}
  `

  return (
    <Wrap>
      {nodes.map((work, index) => (
        <Link to={work.uri} key={index} aria-label={work.title}>
          <FeaturedImage color={work.projectDetails.tileColor} image={work.featuredImage.node} title={work.title} />
          <Details style={{ backgroundColor: work.projectDetails.tileColor }}>
            <TitleWrap>
              <Title>{work.title}</Title>
              <Client>{work.projectDetails.client}</Client>
            </TitleWrap>
          </Details>
        </Link>
      ))}
    </Wrap>
  )
}

export default WorkListing

export const WorkListingData = graphql`
  fragment WorkListing on WpPage_Pagebuilder_Layouts_WorkListing {
    fieldGroupName
  }
`
