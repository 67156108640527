import React, { useState, useEffect } from 'react'
import { AnimatePresence, useAnimation, LazyMotion, m } from 'framer-motion'

const loadFeatures = () => import('../features.js').then(res => res.default)

const WordList = () => {
  const wordList = ['beautiful', 'fast', 'converting']
  const [currentWordIndex, setCurrentWordIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((currentWordIndex + 1) % wordList.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [currentWordIndex, wordList.length])

  const animation = useAnimation()
  useEffect(() => {
    animation.start({
      rotateY: 180,
      transition: { duration: 0.3 },
    })
  }, [animation, currentWordIndex])

  return (
    <LazyMotion features={loadFeatures}>
      <AnimatePresence>
        <m.i
          key={wordList[currentWordIndex]}
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
        >
          {wordList[currentWordIndex]}
        </m.i>
      </AnimatePresence>
    </LazyMotion>
  )
}

export default WordList
