import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import PropTypes from 'prop-types'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { colors } from '../utils/styles'

const Wrap = styled.section`
  ${tw`max-w-4xl mx-auto p-10 mb-10`}
  .accordion__heading {
    ${tw`py-3 relative font-bold focus:outline-none`}
    .accordion__button {
      ${tw`focus:outline-none`}
      svg {
        ${tw`absolute right-0 top-0 mt-3 md:mr-3
        transform transition ease-out duration-300`}
        width: 1rem;
        fill: ${colors.primary};
      }
      &[aria-expanded='true'] {
        svg {
          ${tw`transform rotate-180 transition ease-out duration-300`}
          fill: black;
        }
      }
    }
  }
  .accordion__panel {
    ${tw`mb-5`}
    p {
      ${tw`mb-5`}
    }
  }
`

const Question = styled.h3`
  ${tw`my-1 pr-5
  text-lg md:text-3xl leading-tight`}
`

const Answer = styled.div`
  ${tw``}
`

const Faq = ({ faqItems }) => (
  <Wrap>
    <Accordion allowZeroExpanded>
      {faqItems.map((item, key) => (
        <AccordionItem key={key}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Question>{item.title}</Question>
              <FontAwesomeSvgIcon icon={faAngleDown} className="text-primary" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Answer
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  </Wrap>
)

Faq.propTypes = {
  faqItems: PropTypes.array.isRequired,
}

export default Faq

export const FaqData = graphql`
  fragment Faq on WpPage_Pagebuilder_Layouts_Faq {
    fieldGroupName
    faqItems {
      ... on WpFaq {
        id
        title
        content
      }
    }
  }
`
