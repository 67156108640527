import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const Wrap = styled.header`
  ${tw`max-w-3xl mx-auto px-8 md:px-10 py-10 md:py-20
  text-center`}
`

const PageTitle = styled.h2`
  ${tw`mb-5 text-2xl sm:text-3xl md:text-5xl font-bold leading-tight`}
`

const Leading = styled.div`
  ${tw`sm:text-2xl px-8 md:px-0 mb-8`}

  h1 {
    ${tw`inline`}
  }

  div {
    ${tw`mb-5 md:mb-8`}
  }
`

const PageHeader = ({ title, leading }) => (
  <Wrap>
    <PageTitle>{title}</PageTitle>
    <Leading
      dangerouslySetInnerHTML={{
        __html: leading,
      }}
    />
  </Wrap>
)

PageHeader.propTypes = {
  leading: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default PageHeader

export const PageHeaderData = graphql`
  fragment PageHeader on WpPage_Pagebuilder_Layouts_PageHeader {
    fieldGroupName
    title
    leading
  }
`
