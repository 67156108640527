import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import FeaturedImage from './Work/FeaturedImage'
import Details from './Work/Details'

const WorkHeader = ({ client, tileColor, title, image }) => {
  const Wrap = styled.div`
    ${tw`lg:flex mb-10`}
  `

  const DetailsWrap = styled.div`
    ${tw`lg:w-1/2 flex lg:ml-1 lg:order-2 items-center`}
    background-color: ${tileColor}
  `

  const FeaturedImageWrap = styled.div`
    ${tw`lg:w-1/2 lg:mr-1`}
    background-color: ${tileColor}
  `

  return (
    <Wrap>
      <DetailsWrap>
        <Details client={client} title={title} tileColor={tileColor} />
      </DetailsWrap>
      <FeaturedImageWrap>
        <FeaturedImage image={image} color={tileColor} title={title} />
      </FeaturedImageWrap>
    </Wrap>
  )
}

WorkHeader.propTypes = {
  client: PropTypes.string,
  image: PropTypes.object,
  tileColor: PropTypes.string,
  title: PropTypes.string,
}

export default WorkHeader
