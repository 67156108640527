import React from 'react'
import PropTypes from 'prop-types'
import Clients from '../layouts/Clients'
import Faq from '../layouts/Faq'
import Hero from '../layouts/Hero'
import ImageFullWidth from '../layouts/ImageFullWidth'
import Link from '../layouts/Link'
import PageHeader from '../layouts/PageHeader'
import TextBlock from '../layouts/TextBlock'
import WorkListing from '../layouts/WorkListing'
import WorkListingHome from '../layouts/WorkListingHome'

const AllLayouts = ({ layoutData }) => {
  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  // eslint-disable-next-line react/no-unstable-nested-components
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType} </div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    Page_Pagebuilder_Layouts_Clients: Clients,
    Page_Pagebuilder_Layouts_Faq: Faq,
    Page_Pagebuilder_Layouts_Hero: Hero,
    Page_Pagebuilder_Layouts_ImageFullWidth: ImageFullWidth,
    Page_Pagebuilder_Layouts_Link: Link,
    Page_Pagebuilder_Layouts_PageHeader: PageHeader,
    Page_Pagebuilder_Layouts_TextBlock: TextBlock,
    Page_Pagebuilder_Layouts_WorkListing: WorkListing,
    Page_Pagebuilder_Layouts_WorkListingHome: WorkListingHome,
    Page_default: Default,

    Work_Pagebuilder_Layouts_ImageFullWidth: ImageFullWidth,
    Work_Pagebuilder_Layouts_TextBlock: TextBlock,
    Work_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts.Page_default

  return <ComponentTag {...layoutData} />
}

AllLayouts.propTypes = {
  layoutData: PropTypes.object.isRequired,
}

export default AllLayouts
