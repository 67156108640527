import React from 'react'
import { AnimatePresence } from 'framer-motion'
import GlobalContextProvider from './src/contexts/GlobalContextProvider'

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  </GlobalContextProvider>
)
