import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Button from '../components/Button'

const Wrap = styled.div`
  ${tw`text-center py-10`}
`

const Link = ({ link }) => {
  const { title, url } = link
  return (
    <Wrap>
      <Button url={url} title={title} />
    </Wrap>
  )
}

Link.propTypes = {
  link: PropTypes.object.isRequired,
}

export const LinkData = graphql`
  fragment Link on WpPage_Pagebuilder_Layouts_Link {
    fieldGroupName
    link {
      target
      title
      url
    }
  }
`

export default Link
